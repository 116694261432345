import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
//
import { useAuth } from '../providers/AuthProvider';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
};
interface RoleBasedGuardProps {
  children?: React.ReactNode;
  hasContent?: boolean;
  roles: string[];
}
export default function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProps) {
  // Logic here to get current user role
  const {user} = useAuth();
  const currentRole = user?.userRole;
  // const currentRole = 'user';

  if (typeof roles !== 'undefined' && currentRole!==undefined && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container sx={{ textAlign: 'center' }}>
        <MotionContainer initial="initial" open animate={true}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Permission Denied
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>
        </MotionContainer>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
