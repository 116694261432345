import { useState, useEffect } from 'react';
// next
import { useRouter } from 'next/router';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
//
import NavItem from './NavItem';

interface NavListProps {
  data: any;
  depth: number;
  hasChild: boolean;
  onClicking?: () => void;
}

export default function NavList({ data, depth, hasChild }: NavListProps) {
  const { pathname, asPath } = useRouter();

  // const { active, isExternalLink } = useActiveLink(data.path);

  const active = asPath === data.path;
  const isExternalLink = false;

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem item={data} depth={depth} open={open} active={active} isExternalLink={isExternalLink} onClick={handleToggle} />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

export function NavListOnClick({ data, depth, hasChild, onClicking }: NavListProps) {
  const { pathname } = useRouter();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  return (
    <>
      <NavItem item={data} depth={depth} open={open} active={active} isExternalLink={isExternalLink} onClick={onClicking} />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

interface NavSubListProps {
  data: any[];
  depth: number;
}

function NavSubList({ data, depth }: NavSubListProps) {
  return (
    <>
      {data.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={depth + 1} hasChild={!!list.children} />
      ))}
    </>
  );
}
