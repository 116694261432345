import { memo } from 'react';
// @mui
import { Box } from '@mui/material';
//
import { StyledRootScrollbar, StyledScrollbar } from './styles';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';
import { useAtomValue } from 'jotai';
import { isMobile } from 'react-device-detect';

// ----------------------------------------------------------------------

interface ScrollbarProps {
  sx?: object;
  children?: React.ReactNode;
}

function Scrollbar({ children, sx, ...other }: ScrollbarProps) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const safeAreaInsetTop = useAtomValue(safeAreaInsetTopAtom);

  if (isMobile) {
    return (
      <Box
        sx={{
          overflowX: 'auto',
          paddingTop: `${safeAreaInsetTop || 0}px`,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
}

export default memo(Scrollbar);
