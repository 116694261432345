import React, { ElementType, forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';

interface IconifyProps extends BoxProps {
  sx?: object;
  width?: number | string;
  icon: ElementType | string;
}

const Iconify = forwardRef<React.ElementRef<typeof Box>, IconifyProps>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

export default Iconify;
