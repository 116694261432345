import PropTypes from 'prop-types';
// next
import NextLink from 'next/link';
// @mui
import { Box, Tooltip, Link, ListItemText, useTheme } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import Iconify from '../../iconify';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import RoleBasedGuard from '@/auth/RoleBasedGuard';
// import { useScrollContext } from '@/utils/ScrollContext';

// ----------------------------------------------------------------------

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
};

interface NavItemProps {
  open: boolean;
  active: boolean;
  item: Item;
  depth: number;
  isExternalLink: boolean;
  onClick?: () => void;
}

interface Item {
  title: string;
  path: string;
  icon: string;
  img: string;
  info: string;
  children: Item[];
  disabled: boolean;
  caption: string;
  roles: string[];
  onClick?: () => void;
}

export default function NavItem({ item, depth, open, active, isExternalLink, ...other }: NavItemProps) {
  const { translate } = useLocales();

  const { title, path, icon, img, info, children, disabled, caption, roles } = item;

  const subItem = depth !== 1;

  const theme = useTheme();

  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {img ? (
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(3),
            backgroundImage: `url(${img})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.6)`,
          }}
        />
      ) : (
        icon && <StyledIcon sx={{ marginRight: theme.spacing(3), width: 24 }}>{icon}</StyledIcon>
      )}
      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={`${translate(title)}`}
        secondary={
          caption && (
            <Tooltip title={`${translate(caption)}`} placement="top-start">
              <span>{`${translate(caption)}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body1',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify width={16} icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ ml: 1, flexShrink: 0 }} />
      )}
    </StyledItem>
  );

  // const handlePageChange = (event, value) => {
  //   window.scrollTo(0, 0);
  //   setScrollTop({ ...scrollTop, [path]: true });
  // };

  const renderItem = (data: Item) => {
    // ExternalLink
    if (isExternalLink) {
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );
    }
    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    if (path === '###') {
      return (
        <>
          <Link
            component={NextLink}
            href={path}
            underline="none"
            onClick={(e) => {
              e.preventDefault();
              // Your onClick logic here
            }}
          >
            {renderContent}
          </Link>
        </>
      );
    } else {
      return (
        <Link
          component={NextLink}
          href={path}
          onClick={(e) => {
            if (data.onClick) {
              data.onClick();
            }
            // Your onClick logic here
          }}
          shallow={true}
          sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}
        >
          {renderContent}
        </Link>
      );
    }
  };

  return <RoleBasedGuard roles={roles}> {renderItem(item)} </RoleBasedGuard>;
}
