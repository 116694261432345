// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemIcon, ListSubheader, ListItemButton, ListItemButtonProps } from '@mui/material';
// config
import { ICON, NAV } from '../../../config-global';

// ----------------------------------------------------------------------

interface SidebarItemProps {
  active?: boolean;
  disabled?: boolean;
  depth?: number;
  caption?: boolean;
  theme?: any;
}

// Merge SidebarItemProps with ListItemButtonProps
type CustomListItemButtonProps = SidebarItemProps & ListItemButtonProps;

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'caption',
})<CustomListItemButtonProps>(({ active, disabled, depth, caption, theme }) => {
  const isLight = theme.palette.mode === 'light';

  const subItem = depth !== 1;

  const activeStyle = {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const activeSubStyle = {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  };

  return {
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    height: NAV.H_DASHBOARD_ITEM,
    // Sub item
    ...(subItem && {
      height: NAV.H_DASHBOARD_ITEM_SUB,
      ...(depth &&
        depth > 2 && {
          paddingLeft: theme.spacing(depth),
        }),
      ...(caption && {
        height: NAV.H_DASHBOARD_ITEM,
      }),
    }),
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle,
      },
    }),
    // Active sub item
    ...(subItem &&
      active && {
        ...activeSubStyle,
        '&:hover': {
          ...activeSubStyle,
        },
      }),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        opacity: 0.64,
      },
    }),
  };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: ICON.NAV_ITEM,
  height: ICON.NAV_ITEM,
});

// ----------------------------------------------------------------------

interface SideDotProps {
  active?: boolean;
  theme?: any;
}

type CustomSpanProps = SideDotProps & React.HTMLAttributes<HTMLSpanElement>;

export const StyledDotIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})<CustomSpanProps>(({ active, theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    transform: 'scale(2)',
    backgroundColor: theme.palette.primary.main,
  }),
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)<{ index: number }>`
  font-size: 1.25em;
  color: ${(props) => props.theme.palette.text.primary};
  padding-left: ${(props) => props.theme.spacing(props.index === 0 ? 2 : 1)};
  text-indent: ${(props) => props.theme.spacing(props.index === 0 ? 2 : 1)};
  padding-top: ${(props) => props.theme.spacing(0)};
  font-weight: ${(props) => (props.index === 0 ? 'normal' : 400)};
  &:before {
    content: ${(props) => (props.index === 0 ? '""' : 'none')};
    display: ${(props) => (props.index === 0 ? 'block' : 'none')};
    height: 1px;
    box-shadow: ${(props) => (props.index === 0 ? `inset 0 -0.2px 0 0 ${props.theme.palette.common.white}` : 'none')};
    margin-bottom: ${(props) => props.theme.spacing(1.5)};
    margin-top: ${(props) => props.theme.spacing(1.5)};
    margin-left: ${(props) => props.theme.spacing(-2)};
    margin-right: ${(props) => props.theme.spacing(-2)};
  }
`;
