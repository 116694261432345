import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, useTheme, Button } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  currentConversationIdAtom,
  messagesAtom,
  localMessagesAtom,
  conversationsAtom,
  NewConversationMode,
  newConversationTriggerAtom,
  newChatModeSelectorAtom,
} from './Chat.states';
import Scrollbar from '@/components/scrollbar';
import { AppActiveSection, appActiveSectionModeAtom } from '@/src/atoms/useApp.states';
import { useRouter } from 'next/router';
import { PATH_DASHBOARD } from '@/routes/paths';
import { isMobile } from 'react-device-detect';
import useLocales from '@/locales/useLocales';
import { EditMode } from '../menu/Content.hooks';
import { editModeChatAtom } from '../menu/Content.hooks';

const ConversationsList = () => {
  const theme = useTheme();
  const setCurrentConversationId = useSetAtom(currentConversationIdAtom);
  const currentConversationId = useAtomValue(currentConversationIdAtom);
  const setMessages = useSetAtom(messagesAtom);
  const setLocalMessages = useSetAtom(localMessagesAtom);
  const setActiveSectionMode = useSetAtom(appActiveSectionModeAtom);
  const setEditModeChat = useSetAtom(editModeChatAtom);
  const setNewConversationTrigger = useSetAtom(newConversationTriggerAtom);
  const router = useRouter();
  const setShowNewChatModeSelector = useSetAtom(newChatModeSelectorAtom);

  const handleSelectConversation = (convId: number, editMode: EditMode) => {
    router.push(PATH_DASHBOARD.general.chat, undefined, { shallow: true });
    setCurrentConversationId(convId);
    setEditModeChat(editMode);
    setActiveSectionMode(AppActiveSection.Chat);
  };

  const resetConversation = () => {
    setCurrentConversationId(null);
    setMessages([]);
    setLocalMessages([]);
  };

  const handleNewChat = () => {
    resetConversation();
    setShowNewChatModeSelector(true);
    // setNewConversationTrigger(NewConversationMode.InitNewChat);
    router.push(PATH_DASHBOARD.general.chat, undefined, { shallow: true });
    // setActiveSectionMode(AppActiveSection.Chat);
  };

  const conversations = useAtomValue(conversationsAtom);
  const { translate } = useLocales();

  return (
    <>
      {isMobile && (
        <Button variant="contained" color="primary" fullWidth onClick={handleNewChat} sx={{ mt: 2, mb: 1 }}>
          {translate('chatInterface.startANewChat')}
        </Button>
      )}
      <Scrollbar
        sx={{
          height: 'calc(100% - 48px)',
          display: 'flex',
          maxHeight: isMobile ? '70vh' : '30vh',
          marginBottom: theme.spacing(2),
          flexDirection: 'column',
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <List>
          {conversations.map((conv) => (
            <ListItem
              button
              key={conv.id}
              selected={conv.id === currentConversationId}
              onClick={() => handleSelectConversation(conv.id, conv.editMode)}
              sx={{ borderRadius: theme.spacing(1) }}
            >
              <ListItemText
                primary={conv.title}
                secondary={`${new Date(conv.startedAt).toLocaleDateString()} ${new Date(conv.startedAt).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}`}
              />
            </ListItem>
          ))}
        </List>
      </Scrollbar>
    </>
  );
};

export default ConversationsList;
